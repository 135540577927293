var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _vm._l(_vm.dtlFlds1, function (item, index) {
                    return _c("details-card", {
                      key: index,
                      attrs: {
                        header: item.header,
                        edit: item.edit || false,
                        shortcut: item.shortcut,
                        fields: item.fields,
                        beForm: _vm.beForm,
                        data: _vm.details,
                        repository: item.repository,
                      },
                      on: { modal: _vm.onOpenDetailsModal },
                    })
                  }),
                  _vm._v(" "),
                  _vm.coassDetails.length
                    ? _c(
                        "b-card",
                        {
                          staticClass: "mt-2",
                          attrs: { header: "true", "header-tag": "header" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 d-inline-block align-middle",
                                },
                                [_c("b", [_vm._v("Coassicurazioni")])]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-card-text",
                            [
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    "Nostra quota per coass delega Nostra:"
                                  ),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.details.sharing) +
                                    "%\n          "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("b-table", {
                                attrs: {
                                  striped: "",
                                  hover: "",
                                  items: _vm.coassDetails,
                                  fields: _vm.coassDetailFields,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(compagnia_delegata)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(row.item.code_importer) +
                                              " - " +
                                              _vm._s(row.item.title_importer) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(agenzie_delegate)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(row.item.code) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(quote_attribuite)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(row.item.pivot.share) +
                                              "%\n            "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  693896733
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.dtlFlds2, function (item, index) {
                    return _c("details-card", {
                      key: index,
                      attrs: {
                        header: item.header,
                        edit: item.edit || false,
                        shortcut: item.shortcut,
                        fields: item.fields,
                        beForm: _vm.beForm,
                        data: _vm.details,
                        repository: item.repository,
                      },
                      on: { modal: _vm.onOpenDetailsModal },
                    })
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.detailFields, function (item, index) {
                    return _c("details-card", {
                      key: index,
                      attrs: {
                        header: item.header,
                        edit: item.edit || false,
                        fields: item.fields,
                        beForm: _vm.beForm,
                        data: _vm.details,
                        repository: item.repository,
                      },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("details-modal", {
                attrs: {
                  resourceId: _vm.modalResourceId,
                  name: `detailsModal${_vm.modalResourceId}`,
                },
                on: { modal: _vm.onOpenDetailsModal },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }